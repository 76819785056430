import type {RefCallback, RefObject} from 'preact';
import {forwardRef} from 'preact/compat';
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'preact/hooks';

import {useAuthorizeState} from '~/foundation/AuthorizeState/hooks';
import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {useOpenTelemetry} from '~/foundation/OpenTelemetry/hooks';
import type {AuthorizeEventHandlers} from '~/hooks/useAuthorizeEventListener';
import {useAuthorizeEventListener} from '~/hooks/useAuthorizeEventListener';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useLoadTimeout} from '~/hooks/useLoadTimeout';
import {usePrevious} from '~/hooks/usePrevious';
import type {
  ShopModalShownReason,
  ShopModalHiddenDismissMethod,
} from '~/types/analytics';
import type {SdkErrorCode} from '~/types/authorize';
import type {PayEvents} from '~/types/event';
import type {IframeElement, IframeElementCloseParams} from '~/types/iframe';
import type {PortalProviderVariant} from '~/types/portalProvider';
import {debounce} from '~/utils/debounce';
import {isoDocument} from '~/utils/document';
import {postMessage} from '~/utils/postMessage';
import {updateIframeSrc} from '~/utils/updateIframeSrc';

import {Modal} from '../Modal/Modal';

import {LoadingSkeleton} from './LoadingSkeleton';

export interface AuthorizeIframeProps extends AuthorizeEventHandlers {
  activator?: RefObject<HTMLElement>;
  allowAttribute?: string;
  anchorTo?: string;
  autoOpen?: boolean;
  disableDefaultIframeResizing?: boolean;
  insideModal?: boolean;
  keepModalOpen?: boolean;
  modalHeaderTitle?: string;
  modalHeaderVisible?: boolean;
  proxy: boolean;
  src: string;
  storefrontOrigin?: string;
  variant: PortalProviderVariant;
}

const SILENT_SDK_ERROR_CODES: SdkErrorCode[] = ['captcha_challenge'];

export const AuthorizeIframe = forwardRef<IframeElement, AuthorizeIframeProps>(
  (
    {
      activator,
      allowAttribute,
      anchorTo,
      autoOpen,
      disableDefaultIframeResizing = false,
      insideModal = true,
      keepModalOpen = false,
      modalHeaderTitle,
      modalHeaderVisible = true,
      onComplete,
      onError,
      onLoaded,
      onModalVisibleChange,
      onResizeIframe,
      onPromptContinue,
      proxy,
      src,
      storefrontOrigin,
      variant,
    },
    ref,
  ) => {
    const {autoOpened, dispatch, loaded, modalVisible, sessionDetected} =
      useAuthorizeState();
    const {leaveBreadcrumb, notify} = useBugsnag();
    const dispatchEvent = useDispatchEvent();
    const {clearLoadTimeout, initLoadTimeout} = useLoadTimeout();
    const {trackModalStateChange, trackPageImpression} = useMonorail();
    const {recordCounter} = useOpenTelemetry();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const prevModalVisible = usePrevious(modalVisible);

    const callbackRef: RefCallback<HTMLIFrameElement> = (ref) => {
      /**
       * When the user restarts, the render logic changes for our iframe and ref is set to null.
       * We want to prevent from overriding the ref and setting it to null here because it breaks
       * the postMessage logic.
       */
      if (!ref) {
        return;
      }

      iframeRef.current = ref;

      /**
       * Init iframe src if not set already. We need this because iframeRef.current is undefined on
       * initial render, therefore we're not guaranteeed to successfully set the iframe src via
       * iframeRef.
       */
      if (!ref.getAttribute('src')) {
        ref.setAttribute('src', src);
      }
    };

    useEffect(() => {
      if (loaded) {
        trackModalStateChange({
          currentState: 'loaded',
          reason: 'event_loaded',
        });
        leaveBreadcrumb('iframe loaded', {}, 'state');
      }
    }, [leaveBreadcrumb, loaded, trackModalStateChange]);

    const handleShowModal = useCallback(
      (reason: ShopModalShownReason) => {
        dispatch({type: 'setModalVisible', payload: true});
        trackModalStateChange({
          currentState: 'shown',
          reason,
        });
      },
      [dispatch, trackModalStateChange],
    );

    useEffect(() => {
      if (autoOpen && loaded && sessionDetected && !autoOpened) {
        handleShowModal('event_loaded_with_auto_open');
        dispatch({type: 'setAutoOpened', payload: true});
      }
    }, [
      autoOpen,
      autoOpened,
      dispatch,
      handleShowModal,
      loaded,
      sessionDetected,
    ]);

    const handleDismissModal = useCallback(
      ({dismissMethod, reason}: IframeElementCloseParams) => {
        if (!modalVisible) {
          return;
        }

        dispatch({type: 'setModalVisible', payload: false});

        // Focuses the activator after the modal closes.
        if (activator?.current && isRef(activator)) {
          activator.current.focus();
        }

        trackModalStateChange({
          currentState: 'hidden',
          dismissMethod,
          reason,
        });
      },
      [activator, dispatch, modalVisible, trackModalStateChange],
    );

    useEffect(() => {
      function onClick() {
        handleShowModal('user_button_clicked');
      }

      const debouncedOnClick = debounce(onClick, 150, true);

      const internalActivatorRef = activator;

      if (internalActivatorRef?.current && isRef(internalActivatorRef)) {
        internalActivatorRef.current.addEventListener(
          'click',
          debouncedOnClick,
        );

        return () => {
          internalActivatorRef.current?.removeEventListener(
            'click',
            debouncedOnClick,
          );
        };
      }
    }, [activator, handleShowModal]);

    const reloadIframe = useCallback(() => {
      updateIframeSrc({iframe: iframeRef.current, src});
    }, [src]);

    const {destroy, waitForMessage} = useAuthorizeEventListener({
      includeCore: proxy,
      onClose: () =>
        handleDismissModal({
          dismissMethod: 'auto',
          reason: 'event_close_requested',
        }),
      onComplete: async (completedEvent) => {
        if (!keepModalOpen && insideModal) {
          handleDismissModal({
            dismissMethod: 'auto',
            reason: 'event_completed',
          });
        }
        await onComplete?.(completedEvent);
      },
      onError: (event) => {
        const {message, code} = event;
        if (SILENT_SDK_ERROR_CODES.includes(code)) {
          recordCounter('shop_js_handle_silent_error', {
            attributes: {
              errorCode: code,
            },
          });
          leaveBreadcrumb('silent error', {code}, 'state');
        } else {
          notify(new Error(`Authorize Error: ${message} (${code}).`));
        }

        clearLoadTimeout();
        onError?.(event);
      },
      onLoaded: (event) => {
        dispatch({
          type: 'updateState',
          payload: {
            loaded: true,
            sessionDetected: event.userFound,
          },
        });
        onLoaded?.(event);
        clearLoadTimeout();
      },
      onResizeIframe: (event) => {
        if (!disableDefaultIframeResizing) {
          if (iframeRef.current) {
            iframeRef.current.style.height = `${event.height}px`;
          }
        }

        onResizeIframe?.(event);
      },
      onShopUserMatched: ({userCookieExists}) => {
        dispatchEvent('shopusermatched');
        dispatch({type: 'setSessionDetected', payload: userCookieExists});
        leaveBreadcrumb('shop user matched', {}, 'state');
      },
      onShopUserNotMatched: ({apiError}) => {
        dispatchEvent('shopusernotmatched', apiError && {apiError});
        dispatch({type: 'setSessionDetected', payload: false});
        leaveBreadcrumb('shop user not matched', {}, 'state');
      },
      onPromptContinue: () => {
        onPromptContinue?.();
      },
      source: iframeRef,
      storefrontOrigin,
    });

    useEffect(() => {
      return () => {
        if (iframeRef.current) {
          destroy();
        }
      };
    }, [destroy]);

    useEffect(() => {
      // Do not run the effect if the modalVisible state has not changed
      // This helps us prevent sending duplicated events
      if (modalVisible === prevModalVisible) {
        return;
      }

      if (modalVisible) {
        try {
          postMessage({
            contentWindow: iframeRef.current?.contentWindow,
            event: {
              type: 'sheetmodalopened',
            },
          });
          dispatchEvent('modalopened');
        } catch (error) {
          // Create an easily identifiable error message to help
          // debug issues with the CheckoutModal conversion drop
          notify(
            new Error(
              `Error before calling onModalVisibleChange(true): ${error}`,
            ),
          );
        }

        onModalVisibleChange?.(true);
        return;
      }

      postMessage({
        contentWindow: iframeRef.current?.contentWindow,
        event: {
          type: 'sheetmodalclosed',
        },
      });
      dispatchEvent('modalclosed');
      onModalVisibleChange?.(false);

      // Remove the 1password custom element from the DOM after the sheet modal is closed.
      isoDocument.querySelector('com-1password-notification')?.remove();
    }, [
      dispatchEvent,
      modalVisible,
      notify,
      onModalVisibleChange,
      prevModalVisible,
    ]);

    useImperativeHandle(ref, () => {
      return {
        close: handleDismissModal,
        iframeRef,
        open: handleShowModal,
        postMessage: (event: PayEvents) =>
          postMessage({contentWindow: iframeRef.current?.contentWindow, event}),
        reload: reloadIframe,
        waitForMessage,
      };
    }, [handleDismissModal, handleShowModal, reloadIframe, waitForMessage]);

    useEffect(() => {
      initLoadTimeout();
      leaveBreadcrumb('Iframe url updated', {src}, 'state');
    }, [initLoadTimeout, leaveBreadcrumb, src]);

    useEffect(() => {
      if (modalVisible) {
        trackPageImpression({page: 'AUTHORIZE_MODAL'});
      }
    }, [modalVisible, trackPageImpression]);

    useEffect(() => {
      updateIframeSrc({iframe: iframeRef.current, src});
    }, [src]);

    const handleModalInViewport = () => {
      trackPageImpression({
        page: 'AUTHORIZE_MODAL_IN_VIEWPORT',
        allowDuplicates: true,
      });
      leaveBreadcrumb('modal in viewport', {}, 'state');
    };

    const iframeElement = (
      <iframe
        allow={allowAttribute || 'publickey-credentials-get *'}
        className="relative z-40 m-auto w-full border-none"
        ref={callbackRef}
        tabIndex={0}
        data-testid="authorize-iframe"
      />
    );

    return insideModal ? (
      <Modal
        anchorTo={anchorTo}
        headerTitle={modalHeaderTitle}
        hideHeader={!modalHeaderVisible}
        onDismiss={(dismissMethod: ShopModalHiddenDismissMethod) =>
          handleDismissModal({
            dismissMethod,
            reason: 'user_dismissed',
          })
        }
        onModalInViewport={handleModalInViewport}
        variant={variant}
        visible={modalVisible}
      >
        <LoadingSkeleton>{iframeElement}</LoadingSkeleton>
      </Modal>
    ) : (
      iframeElement
    );
  },
);

AuthorizeIframe.displayName = 'AuthorizeIframe';

function isRef(ref: RefObject<HTMLElement>): ref is RefObject<HTMLElement> {
  return Object.prototype.hasOwnProperty.call(ref, 'current');
}
