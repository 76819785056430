import type {Toast} from '~/types/toast';
import {getStorageItem, setStorageItem} from '~/utils/storage';

const STORAGE_KEY = 'signInWithShop';

export function getToastFromStorage(key: string): Toast | null {
  try {
    return JSON.parse(
      getStorageItem(`${STORAGE_KEY}:${key}`, {
        session: true,
      }) as string,
    ) as Toast;
  } catch {
    return null;
  }
}

export function setToastInStorage(key: string, value: Toast | null): void {
  setStorageItem(`${STORAGE_KEY}:${key}`, JSON.stringify(value), {
    session: true,
  });
}
